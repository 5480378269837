// mainnet
export const DefiAddress = '0xdd577fb147e2ec7f0915e9614029b20db9599227';
export const USDTAddress = '0xdAC17F958D2ee523a2206206994597C13D831ec7';
export const BscUSDTAddress = '0x55d398326f99059ff775485246999027b3197955';
export const EthBridgeAddress = '0x0cB4CD7A807F079Bc5955Cecf86987f01a61E1e1';
export const bscBridgeAddress = '0x8DD3e3Af35cD4F9bd026A49612476D2Dfb5f225f';
export const rpcURL = 'https://mainnet.infura.io/v3/80d11963e8f5426d896589e98c287c95';
export const BscRpcURL = 'https://bsc-dataseed.binance.org/';

// // rinkeby
// export const DefiAddress = '0x95982FE6A3E22F18BEA503530EBE2517531011d6';
// // export const USDTAddress = '0xdAC17F958D2ee523a2206206994597C13D831ec7';
// export const USDTAddress = '0x9e3bc7a1774b31d8fc65d866bd6bd809d3a7427b';
// export const BscUSDTAddress = '0x337610d27c682e347c9cd60bd4b3b107c9d34ddd';
// export const EthBridgeAddress = '0xEba860e1a635B0941027E2Fbb48343BE860Dd421';
// export const bscBridgeAddress = '0xBde3515Ad6C4F5CB176F607adb617E2cECb1fcE5';
// export const rpcURL = 'https://rinkeby.infura.io/v3/80d11963e8f5426d896589e98c287c95';
// export const BscRpcURL = 'https://data-seed-prebsc-1-s1.binance.org:8545';