import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios);

export default new Vuex.Store({
  state: {
    account: '',
    chainId: false,
    isBSC: false,
    tokenList: [],
    EGTAddress: '',
    ETHAddress: '',
    GameAddress: '',
    ADTAddress: '',
    locale: 'tw',
    langs: ['tw', 'en', 'jp']
  },
  mutations: {
    updateAccount(state, account){
      state.account = account
    },
    updateChainId(state, chainId){
      state.chainId = chainId
    },
    updateIsBSC(state, isBSC){
      state.isBSC = isBSC
    },
    updateTokenList(state, tokenList){
      state.tokenList = tokenList
      let EGT = tokenList.find((item)=>item.name === 'egt')
      state.EGTAddress = EGT.tokenaddress
      let ETH = tokenList.find((item)=>item.name === 'eth')
      state.ETHAddress = ETH.tokenaddress
    },
    updateAddress(state, address){
      state.ADTAddress = address.adt
      state.GameAddress = address.game
    },
    updateLang(state, lang){
      state.locale = lang
    },
    clearInfo(state){
      state.account = ''
    },
  },
  actions: {
    async getTokenPrice({ state }, data){
      console.log('state', state.chainId)
      let result = await Vue.axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${data.token}&vs_currencies=${data.currency}&include_last_updated_at=true`)
      return result.data
    },
    async getAllOrders(){
      let result = await Vue.axios.post(`https://jackpot.egtdefi.com/api/v1/orders`)
      // let result = await Vue.axios.post(`https://staging-jackpot.egtdefi.com/api/v1/orders`)
      return result.data
    },
    async getAllRecords({ state }, data){
      console.log('state', state.chainId)
      // let result = await Vue.axios.get(`https://api-rinkeby.etherscan.io/api?module=account&action=txlist&&address=${data.defiAddress}&startblock=${data.startBlock}&endblock=${data.endBlock}&page=${data.page}&apikey=C9RIE4CZZGHNE7FICYK8FWWUC2MP8WAR86`)
      let result = await Vue.axios.get(`https://api.etherscan.io/api?module=account&action=txlist&&address=${data.defiAddress}&startblock=${data.startBlock}&endblock=${data.endBlock}&page=${data.page}&apikey=C9RIE4CZZGHNE7FICYK8FWWUC2MP8WAR86`)
      return result.data
    },
  },
  modules: {
  }
})
